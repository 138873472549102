<template>
  <div>
    <div
      v-viewer="{movable: false}"
      class="images"
    >
      <h2
        class="text-center mb-1"
      ><u><b>
        เลือกเอกสารที่ไม่ต้องการส่ง</b>
      </u></h2>
      <br>
      <b-row
        v-if="!emrType"
      >
        <b-col
          v-for="(data, index) in imageList"
          :key="index"
          lg="3"
          md="4"
          sm="6"
        >
          <img
            v-bind="imageConfig"
            :src="data.src"
            style="border: 1px solid black;  box-shadow: 4px 6px;"
            :alt="`Image ${index + 1}`"
            class="mr-1"
          >
          <b-button
            class="d-flex justify-content-center ml-4 mb-1 mt-1"
            variant="danger"
            size="sm"
            @click="saveImage(data)"
          >
            เลือกเอกสาร
          </b-button>
        </b-col>
      </b-row>
      <b-row v-else>
        <viewerEMR
          :key="imageData.data.txn"
          :emr-data="imageData.data"
        />
      </b-row>
      <b-spinner
        v-show="loadingCheck === true"
        class="loadingpic"
        label="Loading..."
      />
    </div>
    <div class="justify-content-center d-flex mb-1">
      <b-pagination
        v-show="ipdAmount != null"
        v-model="currentPage"
        :total-rows="ipdAmount"
        :per-page="perPage"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        align="left"
        last-text="Last"
      />
    </div>
  </div>

</template>

<script>
import { BPagination } from 'bootstrap-vue'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import viewerEMR from './viewerEMR.vue'
import store from '../../../../store'

Vue.use(VueViewer)
export default {
  components: {
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    viewerEMR,
  },
  props: {
    imageData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imageList: [],
      checkedImages: [],
      imageConfig: {
        blank: false,
        blankColor: '#bbb',
        width: 200,
        height: 250,
      },
      ipdAmount: null,
      currentPage: 1,
      perPage: 30,
      offSet: 0,
      loadingCheck: false,
      imageRow: [],
      options: ['OPD', 'EMR'],
      docType: '',
      emrType: false,
    }
  },
  watch: {
    currentPage(value) {
      const page = value - 1
      this.offSet = page * 30
      this.imageList = []
      this.getAllImage()
    },
  },
  async created() {
    this.hn = store.getters['hieDetail/setHn']
    if (this.imageData.data !== undefined) {
      await this.getAllImage()
    }
    if (this.imageData.type === 'EMR') {
      this.emrType = true
    } else {
      this.emrType = false
    }
    if (this.imageData.type === 'IPD') {
      await this.findIpdImageAmount()
    }
  },
  methods: {
    async findIpdImageAmount() {
      await this.$http({
        url: '/api/Image/ClinicIPDAmount',
        method: 'POST',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
        data: {
          hn: this.hn,
          clinic: this.imageData.data.clinicDate,
        },
      }).then(({ data }) => {
        this.ipdAmount = data
      })
    },
    async saveImage(value) {
      store.commit('hieDetail/getHieRecordDiscard', value)
      this.showToast()
    },
    showToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'แจ้งเตือน',
          icon: 'BellIcon',
          text: 'คุณเลือกใบแล้ว',
          variant: 'warning',
        },
      })
    },
    async getAllImage() {
      this.loadingCheck = true
      const Type = this.imageData.type
      let imageDataList = []
      if (Type === 'IPD') {
        imageDataList = await this.getIPDImage()
        this.emrType = false
      } else if (Type === 'Other') {
        imageDataList = await this.getOtherImage()
        this.emrType = false
      } else if (Type === 'MR') {
        imageDataList = await this.getMRImage()
        this.emrType = false
      }
      imageDataList.forEach(element => {
        this.imageList.push({ src: `data:image/jpeg;base64, ${element.imageBase64}`, imageBaseName: element.imageBaseName })
      })
      this.loadingCheck = false
    },
    async getMRImage() {
      let imageDataList = []
      await this.$http({
        url: `/api/Image/AllImageMR/${this.hn}/${this.imageData.data.logDate}/${this.imageData.data.managedBy}/${this.imageData.data.location}`,
        method: 'GET',
        headers: { Authorization: `bearer ${localStorage.getItem('tokenFetchImage')}` },
      }).then(({ data }) => {
        imageDataList = data
      })
      return imageDataList
    },
    async getOtherImage() {
      let imageDataList = []
      if (this.imageData.data.typeName === 'u/s') {
        this.imageData.data.typeName = encodeURIComponent(this.imageData.data.typeName)
      }
      await this.$http({
        url: `/api/Image/AllImageOther/${this.hn}/${this.imageData.data.typeName}`,
        method: 'GET',
      }).then(({ data }) => {
        imageDataList = data
      })
      return imageDataList
    },
    async getIPDImage() {
      let imageDataList = []
      await this.$http({
        url: `/api/Image/AllImageIPD/${this.hn}/${this.imageData.data.clinicDate}/${this.offSet}`,
        method: 'GET',
      }).then(({ data }) => {
        imageDataList = data
      })
      return imageDataList
    },
  },
}
</script>

<style>
.loadingpic{
  margin-left: 50%;
  margin-top: 10%;
}
</style>
